import { BasemapList } from "../Map/basemaps";
import { atomFamily, atomWithReset } from "jotai/utils";

type MapViewState = {
    longitude: number;
    latitude: number;
    zoom: number;
    bearing: number;
    pitch: number;
};

export interface MapState {
    _viewState: MapViewState;
    debounced: {
        viewState?: MapViewState;
        areaOnScreen?: any;
    };
    basemap: BasemapList | null;
}

interface MapStateFamily {
    mapId: string;
    initialState?: Partial<MapState>;
}

// Generic atom that allows us to generate custom map
// states using the same basic structure.
export const mapStateFamily = atomFamily(
    ({ initialState }: MapStateFamily) =>
        atomWithReset<MapState>({
            _viewState: {
                longitude: -98.58,
                latitude: 39.82,
                zoom: 4,
                bearing: 0,
                pitch: 0,
            },
            debounced: {},
            basemap: null,
            ...initialState,
        }),
    (a, b) => a.mapId === b.mapId,
);

export type MapSelectedContext = {
    infrastructureId?: string;
    pipeline?: {
        id: string;
        coordinates: number[];
    };
    emissionRecordId?: string;
    dataPointId?: string;
    // Related plume: controls behavior of plumes showing on map.
    // * String: plume ID to display
    // * null: hide all plumes
    // * undefined: show all plumes
    relatedPlume?: string | null;
};

export interface MapDataState {
    selectedContext: MapSelectedContext;
    mapSettings: {
        plumeOpacity: number;
    };
}

interface MapDataStateFamily {
    mapId: string;
    initialState?: Partial<MapDataState>;
}

export const mapDataStateFamily = atomFamily(
    ({ initialState }: MapDataStateFamily) =>
        atomWithReset<MapDataState>({
            selectedContext: {},
            mapSettings: {
                plumeOpacity: 0.5,
            },
            ...initialState,
        }),
    (a, b) => a.mapId === b.mapId,
);
