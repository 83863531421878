import { GeoJsonLayer } from "@deck.gl/layers";
import {
    AerialImagesList,
    GeoJSONFeatureCollection,
    InfrastructureMapList,
    PipelineMapList,
} from "../../../apiClient/generated";
import { MAP_ZOOM_SHOW_DETAILS } from "../constants";
import { BitmapLayer } from "@deck.gl/layers";
import { createFeatureCollection } from "../../../utils/geopatialUtils";
import type { GeoJSON } from "geojson";

export const InfrastructureLayer = (
    data: InfrastructureMapList[],
    visible: boolean,
    zoomLevel: number,
    onHover?: (hoverInfo: any) => void,
) => {
    return [
        new GeoJsonLayer({
            id: "infrastructure",
            data: createFeatureCollection(
                zoomLevel > MAP_ZOOM_SHOW_DETAILS
                    ? data
                    : data.map((v) => {
                          return {
                              ...v,
                              shape: undefined,
                          };
                      }),
            ),
            pointType: "circle",
            filled: true,
            getPointRadius: 6,
            pointRadiusUnits: "pixels",
            lineWidthUnits: "pixels",
            stroked: true,
            pickable: true,
            visible,
            autoHighlight: true,
            onHover,
            highlightColor: [0, 0, 0, 30],
            getFillColor: (item) => {
                if (item.properties!.shape) {
                    return [0, 0, 0, 0];
                }
                return [255, 255, 255, 255];
            },
            getLineWidth: 1,
            getLineColor: [0, 0, 0, 255],
        }),
    ];
};

export const PipelineLayer = (
    data: InfrastructureMapList[],
    visible: boolean,
    zoomLevel: number,
    onHover?: (hoverInfo: any) => void,
) => {
    return [
        new GeoJsonLayer({
            id: "pipelines",
            data: createFeatureCollection(data),
            pointType: "circle",
            filled: true,
            lineWidthUnits: "pixels",
            stroked: true,
            pickable: true,
            lineCapRounded: true,
            lineJointRounded: true,
            visible,
            autoHighlight: true,
            onHover,
            highlightColor: [0, 0, 0, 30],
            getLineWidth: 1.5,
            getLineColor: [0, 0, 0, 230],
        }),
    ];
};

export const PipelineV2Layers = (
    data: PipelineMapList[],
    overviews: GeoJSONFeatureCollection,
    visible: boolean,
    zoomLevel: number,
    onHover?: (hoverInfo: any) => void,
) => {
    const pipelineLayers = [];
    if (zoomLevel <= 10) {
        pipelineLayers.push(
            new GeoJsonLayer({
                id: "pipelineOverviews",
                data: overviews as GeoJSON,
                pointType: "circle",
                filled: true,
                lineWidthUnits: "pixels",
                stroked: false,
                visible,
                autoHighlight: true,
                onHover,
                highlightColor: [0, 0, 0, 120],
                getLineWidth: 1,
            }),
        );
    }

    if (zoomLevel > 10) {
        pipelineLayers.push(
            new GeoJsonLayer({
                id: "pipelines-v2",
                data: createFeatureCollection(data),
                pointType: "circle",
                filled: true,
                lineWidthUnits: "pixels",
                stroked: true,
                pickable: true,
                lineCapRounded: true,
                lineJointRounded: true,
                visible,
                autoHighlight: true,
                onHover,
                highlightColor: [0, 0, 0, 30],
                getLineWidth: 2,
                getLineColor: [0, 0, 0, 230],
            }),
        );
    }

    return pipelineLayers;
};

export const AerialImagesLayer = (
    data: AerialImagesList[],
    visible: boolean,
    zoomLevel: number,
) => {
    if (!data) {
        return [];
    }

    if (zoomLevel < MAP_ZOOM_SHOW_DETAILS) {
        return [];
    }

    // Filter images inside map view and return image array
    return data.map((item) => {
        return new BitmapLayer({
            id: `aerial_image_${item.id}`,
            // FIXME: improve serializer definition of bounds
            bounds: item.bounds as any,
            image: item.image,
            visible: visible,
        });
    });
};
