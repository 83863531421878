import { Popover } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

interface MenuCategoryProps {
    children: string | ReactNode;
}

export const MenuCategory = (props: MenuCategoryProps) => (
    <div className="mb-2 font-medium tracking-wide uppercase text-xs text-zinc-600 flex">
        {props.children}
    </div>
);

interface CheckboxProps {
    label?: string;
    checked: boolean;
    onChange: (newValue: boolean) => void;
    disabled?: boolean;
    icon?: ReactNode;
}

export const Checkbox = (props: CheckboxProps) => (
    <div className="flex items-center gap-3 text-sm">
        <input
            type="checkbox"
            className="rounded mr text-ae-blue-500 disabled:text-neutral-600"
            id={props.label}
            checked={props.checked}
            onChange={(e) =>
                !props.disabled && props.onChange(e.target.checked)
            }
            disabled={props.disabled}
        />
        <label
            htmlFor={props.label}
            className={`flex gap-2 items-center capitalize ${
                props.disabled && "text-neutral-600"
            }`}
        >
            {props.label}
            {props.icon}
        </label>
    </div>
);

interface CheckboxWithMenuProps {
    label: string;
    checked: boolean;
    onChange?: (newValue: boolean) => void;
    children?: ReactNode;
    disabled?: boolean;
    icon?: ReactNode;
}

export const CheckboxWithMenu = (props: CheckboxWithMenuProps) => {
    return (
        <div className="flex">
            <Checkbox
                label={props.label}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled}
                icon={props.icon}
            />

            <div className="flex-grow" />

            {!props.disabled && props.children && (
                <Popover className="relative">
                    <Popover.Button className="p-1 rounded flex items-center justify-center border border-white hover:border-gray-200">
                        <ChevronRightIcon className="w-3" />
                    </Popover.Button>

                    <Popover.Panel className="absolute z-10 top-0 left-10 rounded p-2 bg-white w-52 max-h-96 overflow-scroll">
                        {props.children}
                    </Popover.Panel>
                </Popover>
            )}
        </div>
    );
};
