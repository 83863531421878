import { ROUTES } from ".";
import { SideMenu } from "../components/SideMenu/SideMenu";
import { useAppSelector } from "../hooks";
import { Navigate, Outlet, useLocation, useOutlet } from "react-router-dom";

export const DashboardPage = () => {
    const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
    const flags = useAppSelector((state) => state.auth.flags);
    const location = useLocation();
    const outlet = useOutlet();

    if (!isLoggedIn) {
        const params = {};
        if (location.pathname !== "/") {
            params["next"] = `${location.pathname}${location.search}`;
        }
        const urlParams = new URLSearchParams(params);
        return <Navigate to={`${ROUTES.LOGIN}?${urlParams.toString()}`} />;
    }

    if (outlet === null) {
        // When removing flag -> everyone should be redirected to the dashboard
        if (flags.includes("enable_unified_dashboards")) {
            return <Navigate to={ROUTES.DASHBOARD} />;
        }
        return <Navigate to={ROUTES.DATAUPLOADS} />;
    }

    return (
        <div className="flex bg-ae-gray-100 h-screen w-screen">
            {/* Sidebar */}
            <SideMenu />
            {/* Page content */}
            <div className="w-full h-full overflow-auto">
                <Outlet />
            </div>
        </div>
    );
};
