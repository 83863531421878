import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { useCopyToClipboard } from "@uidotdev/usehooks";
import {
    useAerscapeLayers,
    useDrawingAndFilteringOnMap,
    useEmissionFilters,
    useEmissionRecordsMapData,
    useInfrastructureFilters,
    useInfrastructureMapData,
    usePublicEmissionMapData,
} from "./hooks";
import { DrawingControl } from "./elements/DrawingControl";
import { DataControls } from "./elements/DataControls";
import { MapViewState } from "./CustomTypes";
import { LoadingIndicator } from "./ui/LoadingIndicator";
import { useSearchParams } from "react-router-dom";
import { deserializeMapContext } from "./utils/state";
import { isValidCoordinates } from "../../utils/geopatialUtils";
import { useMap } from "./hooks/mapState";
import { MapBase } from "./Map";
import { MapSidebar } from "./sidebar/MapSidebar";
import { MapScale } from "./elements/MapScale";
import { ZoomAndLegend } from "./elements/ZoomAndLegend";
import { useMapData } from "./hooks/mapDataState";
import { usePipelinesOnMap } from "./hooks/infrastructure";
import { useAppSelector } from "../../hooks";
import type { PickingInfo } from "deck.gl";
import { MapPopup, PickedItems } from "./MapPopup";

export type MapContext = {
    drawAndFilterControls: ReturnType<typeof useDrawingAndFilteringOnMap>;
    mapState: {
        viewstate: MapViewState;
        areaOnScreen: any;
    };
    filters: {
        infrastructure: ReturnType<typeof useInfrastructureFilters>;
        emissions: ReturnType<typeof useEmissionFilters>;
    };
};
export const MapContext = createContext<MapContext>(undefined);

export const MapView = () => {
    // Restore map state from URL if provided
    const [urlParams, setSearchParams] = useSearchParams();
    const userflags = useAppSelector((state) => state.auth.flags);
    const deserializedState = useMemo(() => {
        const rawValue = urlParams.get("mapState");
        if (rawValue) {
            try {
                return deserializeMapContext(rawValue);
            } catch {
                return undefined;
            }
        }
        return undefined;
    }, [urlParams]);

    // Lat and lon coordinates
    const startingCoords = useMemo(() => {
        const lat = parseFloat(urlParams.get("lat"));
        const lon = parseFloat(urlParams.get("lon"));
        if (
            Number.isNaN(lat) ||
            Number.isNaN(lon) ||
            !isValidCoordinates(lat, lon)
        ) {
            return undefined;
        }
        return [lon, lat];
    }, [urlParams]);

    // Clear the `mapState` parameter from the URL
    // to avoid giving the impression it's being
    // update as the user moves the map around.
    useEffect(() => {
        if (
            urlParams.get("mapState") ||
            urlParams.get("lat") ||
            urlParams.get("lon")
        ) {
            setSearchParams(new URLSearchParams());
        }
    }, [urlParams]);

    // Copy to clipboard
    const [, copyToClipboard] = useCopyToClipboard();

    const { _viewState, basemap, debounced } = useMap("mainMap", {
        basemap:
            (deserializedState && deserializedState.basemap) ||
            localStorage.getItem("basemap") ||
            null,
        _viewState: {
            longitude: deserializedState
                ? deserializedState.lon
                : startingCoords
                  ? startingCoords[0]
                  : -98.58,
            latitude: deserializedState
                ? deserializedState.lat
                : startingCoords
                  ? startingCoords[1]
                  : 39.82,
            zoom: deserializedState
                ? deserializedState.z
                : startingCoords
                  ? 15
                  : 4,
            bearing: 0,
            pitch: 0,
        },
    });

    useEffect(() => {
        if (basemap) {
            localStorage.setItem("basemap", basemap as string);
        } else {
            localStorage.removeItem("basemap");
        }
    }, [basemap]);

    // Drawing controls & filter using area state
    const drawing = useDrawingAndFilteringOnMap();

    // Map context states
    const { setSelectedContext } = useMapData("mainMap", {
        selectedContext: {
            ...deserializedState?.mapData.selected,
        },
    });

    // Data filter states
    const infrastructureFilters = useInfrastructureFilters(
        deserializedState && deserializedState.infrastructureFilter,
    );
    const emissionFilters = useEmissionFilters(
        deserializedState && deserializedState.emissionsFilters,
    );

    // Map onClick/context handler
    const [pickedItems, setPickedItems] = useState<PickedItems>();

    const pickItem = useCallback((item: PickingInfo) => {
        let relatedPlume = undefined;
        const layerId = item.layer.id;
        const itemProperties = item.object.properties;
        if (layerId !== "infrastructure" && layerId !== "pipelines") {
            if (itemProperties.plumeImage) {
                relatedPlume = itemProperties.plumeImage;
            } else if (itemProperties.geometry || !itemProperties.plumeImage) {
                relatedPlume = null;
            }
        }
        setSelectedContext({
            infrastructureId:
                layerId === "infrastructure" || layerId === "pipelines"
                    ? itemProperties.id
                    : undefined,
            emissionRecordId: layerId.includes("emissions")
                ? itemProperties.id
                : undefined,
            dataPointId: layerId.includes("datapoint")
                ? itemProperties.id
                : undefined,
            relatedPlume,
            pipeline:
                layerId === "pipelines-v2"
                    ? {
                          id: itemProperties.id,
                          coordinates: item.coordinate,
                      }
                    : undefined,
        });
        setPickedItems(undefined);
    }, []);

    const onClickMap = useCallback(
        (info: PickingInfo[], event: React.MouseEvent<HTMLDivElement>) => {
            // If nothing was clicked, clear selection
            if (info.length == 0) {
                setPickedItems(undefined);
                setSelectedContext({});
            }
            // If only one item is picked, select context immediately.
            else if (info.length == 1) {
                pickItem(info[0]);
            }
            // Else, set items and display tooltip at user cursor position.
            else {
                // Keep old behavior if new_picking_menu is disabled.
                // FIXME: remove when approved.
                if (!userflags.includes("new_picking_behavior")) {
                    pickItem(info[0]);
                    return;
                }

                // Compute clicked emissions
                const infrastructures = info.filter(
                    (i) => i.layer.id === "infrastructure",
                );
                const pipelines = info.filter(
                    (i) => i.layer.id === "pipelines-v2",
                );
                const emissions = info.filter((i) =>
                    i.layer.id.includes("emissions"),
                );

                // If there's only one emission (ignore data points), show modal directly
                if (
                    infrastructures.length == 0 &&
                    pipelines.length == 0 &&
                    emissions.length == 1
                ) {
                    pickItem(emissions[0]);
                } else {
                    // Else set state and show modal.
                    setPickedItems({
                        pointClicked: {
                            x: event.clientX,
                            y: event.clientY,
                        },
                        infrastructures,
                        pipelines,
                        emissions,
                        dataPoints:
                            emissions.length == 0
                                ? info.filter((i) =>
                                      i.layer.id.includes("datapoint"),
                                  )
                                : [],
                    });
                }
            }
        },
        [],
    );

    // If the map changes (zoom, pan, drag) then clear selected context.
    useEffect(() => {
        setPickedItems(undefined);
    }, [_viewState]);

    // Map data fetching
    // FIXME: too much repetition here, this should be refactored to be simpler,
    // maybe useAerscapeData handling all the data fetching and it's results being passed
    // to useAerscape layers to avoid spreading results everywhere here.
    // TODO: there's a small delay to hide the points because
    // this function is also being responsible for controlling
    // rendering of the layers and depends on the debounced view state
    // FIXME: Maybe return sites + equipments separately and handle
    // hiding/showing while layer rendering instead of inside data fetching?
    const infrastructureMapData = useInfrastructureMapData(
        debounced.viewState?.zoom,
        debounced.areaOnScreen,
        useMemo(() => {
            if (userflags.includes("use_new_pipelines")) {
                return infrastructureFilters.infraTypeFilter.filter(
                    (i) => i !== "PIPELINE",
                );
            }
            return infrastructureFilters.infraTypeFilter;
        }, [userflags, infrastructureFilters.infraTypeFilter]),
        infrastructureFilters.pipelineCommodity,
        infrastructureFilters.pipelineSegmentType,
        drawing.filterByArea,
    );

    // New pipeline data loader
    const pipelineMapData = usePipelinesOnMap(
        infrastructureFilters.infraTypeFilter.includes("PIPELINE") &&
            userflags.includes("use_new_pipelines") &&
            infrastructureFilters.showInfrastructure,
        infrastructureFilters.pipelineProduct,
        infrastructureFilters.pipelineType,
        drawing.filterByArea,
    );

    const emissionRecordsThirdPartyMapData = useEmissionRecordsMapData(
        debounced.viewState?.zoom,
        debounced.areaOnScreen,
        useMemo(() => {
            return {
                detectionDateRangeAfter: emissionFilters.startDateFilter,
                detectionDateRangeBefore: emissionFilters.endDateFilter,
                eventStatus: emissionFilters.eventStatus,
                provider: emissionFilters.providerFilter,
                dataSource: emissionFilters.show3rdPartyEmissions
                    ? ["THIRD_PARTY"]
                    : undefined,
            };
        }, [
            emissionFilters.startDateFilter,
            emissionFilters.endDateFilter,
            emissionFilters.eventStatus,
            emissionFilters.providerFilter,
            emissionFilters.show3rdPartyEmissions,
        ]),
        drawing.filterByArea,
    );

    // Public map data
    const emissionRecordsThirdPartyPublicMapData = usePublicEmissionMapData(
        debounced.viewState?.zoom,
        debounced.areaOnScreen,
        emissionFilters.showPublicData,
        useMemo(() => {
            return {
                detectionDateRangeAfter: emissionFilters.startDateFilter,
                detectionDateRangeBefore: emissionFilters.endDateFilter,
                provider: emissionFilters.providerFilter,
                dataSource:
                    emissionFilters.showEmissions &&
                    emissionFilters.show3rdPartyEmissions
                        ? ["THIRD_PARTY"]
                        : undefined,
            };
        }, [
            emissionFilters.startDateFilter,
            emissionFilters.endDateFilter,
            emissionFilters.providerFilter,
            emissionFilters.showPublicData,
            emissionFilters.show3rdPartyEmissions,
        ]),
        drawing.filterByArea,
    );

    const emissionRecordsSelfReportedMapData = useEmissionRecordsMapData(
        debounced.viewState?.zoom,
        debounced.areaOnScreen,
        useMemo(() => {
            return {
                detectionDateRangeAfter: emissionFilters.startDateFilter,
                detectionDateRangeBefore: emissionFilters.endDateFilter,
                eventStatus: emissionFilters.eventStatus,
                provider: emissionFilters.selfReportedProviderFilter,
                dataSource: emissionFilters.showSelfReportedEmissions
                    ? ["SELF_REPORTED"]
                    : undefined,
            };
        }, [
            emissionFilters.startDateFilter,
            emissionFilters.endDateFilter,
            emissionFilters.eventStatus,
            emissionFilters.showSelfReportedEmissions,
            emissionFilters.selfReportedProviderFilter,
        ]),
        drawing.filterByArea,
    );

    const emissionRecordsEpaMapData = useEmissionRecordsMapData(
        debounced.viewState?.zoom,
        debounced.areaOnScreen,
        useMemo(() => {
            return {
                detectionDateRangeAfter: emissionFilters.startDateFilter,
                detectionDateRangeBefore: emissionFilters.endDateFilter,
                eventStatus: emissionFilters.eventStatus,
                dataSource: emissionFilters.showEpaEmissions
                    ? ["EPA"]
                    : undefined,
            };
        }, [
            emissionFilters.startDateFilter,
            emissionFilters.endDateFilter,
            emissionFilters.eventStatus,
            emissionFilters.showEpaEmissions,
        ]),
        drawing.filterByArea,
    );
    // Public map data
    const emissionRecordsEpaPublicMapData = usePublicEmissionMapData(
        debounced.viewState?.zoom,
        debounced.areaOnScreen,
        emissionFilters.showPublicData,
        useMemo(() => {
            return {
                detectionDateRangeAfter: emissionFilters.startDateFilter,
                detectionDateRangeBefore: emissionFilters.endDateFilter,
                provider: emissionFilters.providerFilter,
                dataSource:
                    emissionFilters.showEmissions &&
                    emissionFilters.show3rdPartyEmissions
                        ? ["EPA"]
                        : undefined,
            };
        }, [
            emissionFilters.startDateFilter,
            emissionFilters.endDateFilter,
            emissionFilters.providerFilter,
            emissionFilters.showPublicData,
        ]),
        drawing.filterByArea,
    );

    const emissionRecords = useMemo(
        () => ({
            thirdParty: emissionRecordsThirdPartyMapData.emissions,
            thirdPartyPublic: emissionRecordsThirdPartyPublicMapData.emissions,
            operatorProvided: emissionRecordsSelfReportedMapData.emissions,
            epa: emissionRecordsEpaMapData.emissions,
            epaPublic: emissionRecordsEpaPublicMapData.emissions,
        }),
        [
            emissionRecordsThirdPartyMapData.emissions,
            emissionRecordsSelfReportedMapData.emissions,
            emissionRecordsEpaMapData.emissions,
            emissionRecordsThirdPartyPublicMapData.emissions,
            emissionRecordsEpaPublicMapData.emissions,
        ],
    );

    const plumeImages = useMemo(() => {
        const allImages = [
            ...emissionRecordsThirdPartyMapData.plumeImages,
            ...emissionRecordsSelfReportedMapData.plumeImages,
            ...emissionRecordsEpaMapData.plumeImages,
            ...emissionRecordsEpaPublicMapData.plumeImages,
            ...emissionRecordsThirdPartyPublicMapData.plumeImages,
        ];
        const uniqueImagesSet = new Set();
        return allImages.filter((i) => {
            if (!uniqueImagesSet.has(i.id)) {
                uniqueImagesSet.add(i.id);
                return true;
            }
            return false;
        });
    }, [
        emissionRecordsThirdPartyMapData.plumeImages,
        emissionRecordsSelfReportedMapData.plumeImages,
        emissionRecordsEpaMapData.plumeImages,
        emissionRecordsEpaPublicMapData.plumeImages,
        emissionRecordsThirdPartyPublicMapData.plumeImages,
    ]);

    // Layer rendering
    const layers = useAerscapeLayers(
        infrastructureMapData.infrastructure,
        infrastructureMapData.pipelines,
        infrastructureFilters.showInfrastructure,
        emissionRecords,
        emissionFilters.showEmissions,
        emissionFilters.showPlumes,
        emissionFilters.showPublicData,
        infrastructureMapData.aerialImages,
        plumeImages,
        pipelineMapData.layers,
    );

    const emissionRecordsMapDataLoading =
        pipelineMapData.loading ||
        emissionRecordsThirdPartyMapData.loading ||
        emissionRecordsSelfReportedMapData.loading ||
        emissionRecordsEpaMapData.loading ||
        emissionRecordsEpaPublicMapData.loading ||
        emissionRecordsThirdPartyPublicMapData.loading;
    const loading =
        infrastructureMapData.loading || emissionRecordsMapDataLoading;

    // Context
    const contextData: MapContext = {
        drawAndFilterControls: drawing,
        mapState: {
            viewstate: debounced.viewState,
            areaOnScreen: debounced.areaOnScreen,
        },
        filters: {
            infrastructure: infrastructureFilters,
            emissions: emissionFilters,
        },
    };

    // Render component
    return (
        <MapContext.Provider value={contextData}>
            <div className="flex">
                <div className="relative h-screen w-full overflow-hidden">
                    <MapBase
                        mapId="mainMap"
                        layers={[drawing.drawingLayer, ...layers]}
                        cursor={drawing.isDrawing ? "crosshair" : "grab"}
                        onRightClick={(info) => {
                            copyToClipboard(
                                info.coordinate.reverse().join(","),
                            );
                        }}
                        onLeftClick={onClickMap}
                    />
                    <DataControls />
                    <div className="absolute bottom-6 right-6 flex gap-2 items-end">
                        {loading && <LoadingIndicator />}
                        <MapScale
                            latitude={_viewState.latitude}
                            zoom={_viewState.zoom}
                        />
                        <ZoomAndLegend />
                    </div>
                    <div className="absolute bottom-6 left-6 flex flex-col gap-2">
                        <DrawingControl />
                    </div>
                </div>
                {pickedItems && (
                    <MapPopup
                        picked={pickedItems}
                        onClickItem={(item) => {
                            setPickedItems(undefined);
                            pickItem(item);
                        }}
                    />
                )}
                <MapSidebar />
            </div>
        </MapContext.Provider>
    );
};
