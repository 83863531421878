import {
    InfrastructureDetailSidebar,
    PipelineDetailSidebar,
} from "./infrastructure";
import { EmissionDetailSidebar } from "./emissions";
import { DataPointDetailSidebar } from "./datapoint";
import { useMapData } from "../hooks/mapDataState";

export const MapSidebar = () => {
    /**
     * FIXME:
     * We will have multiple sidebar modes:
     * 1. Infrastructure details
     * 2. Emission details
     * 3. Zoomed in view
     * 4. Search results
     */
    const { selectedContext } = useMapData("mainMap");
    const showSidebar =
        selectedContext.infrastructureId ||
        selectedContext.emissionRecordId ||
        selectedContext.dataPointId ||
        selectedContext.pipeline;

    return (
        <div
            className={`bg-white h-screen transition-all overflow-scroll ${
                showSidebar ? "w-80" : "hidden"
            }`}
        >
            {selectedContext.infrastructureId && (
                <InfrastructureDetailSidebar />
            )}
            {selectedContext.emissionRecordId && <EmissionDetailSidebar />}
            {selectedContext.dataPointId && <DataPointDetailSidebar />}
            {selectedContext.pipeline && <PipelineDetailSidebar />}
        </div>
    );
};
