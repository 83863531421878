import { useDebounce } from "@uidotdev/usehooks";
import { useState, useCallback, useMemo, useEffect } from "react";
import {
    PipelineProductEnum,
    PipelineTypeEnum,
    PipelineMapList,
} from "../../../apiClient/generated";
import { useMapApiClient } from "../../../hooks";
import { useMapDataLoader } from "./dataLoader";
import { useQuery } from "@tanstack/react-query";
import { useMap } from "./mapState";
import { PipelineV2Layers } from "../layers/infrastructure";

export const usePipelinesOnMap = (
    enabled: boolean,
    pipelineProduct: PipelineProductEnum[],
    pipelineType: PipelineTypeEnum[],
    filterByArea?: any,
) => {
    const {
        debounced: { viewState, areaOnScreen },
    } = useMap("mainMap");
    const currentZoom = useMemo(() => viewState?.zoom, [viewState]);

    const apiClient = useMapApiClient();
    const debouncedProduct = useDebounce(pipelineProduct, 400);
    const debouncedPipelineType = useDebounce(pipelineType, 400);
    const [pipelineData, setPipelineData] = useState<PipelineMapList[]>([]);
    const [pipelineDetailsData, setPipelineDetailsData] = useState<
        PipelineMapList[]
    >([]);

    // Retrieve overview data
    const overviewDataLoader = useQuery({
        queryKey: [
            "pipelineOverview",
            debouncedProduct,
            debouncedPipelineType,
            filterByArea,
        ],
        queryFn: async () => {
            return await apiClient.mapPipelinesOverviewsRetrieve({
                pipelineProduct:
                    debouncedProduct.length > 0 ? debouncedProduct : undefined,
                pipelineType:
                    debouncedPipelineType.length > 0
                        ? debouncedPipelineType
                        : undefined,
                locationWithin: filterByArea
                    ? JSON.stringify(filterByArea)
                    : undefined,
                pipelineSizeMin: 3000,
            });
        },
        enabled,
    });

    const fetchDataFn = useCallback(
        (
            setter: React.Dispatch<React.SetStateAction<PipelineMapList[]>>,
            filter: {
                pipelineSizeMax?: number;
                pipelineSizeMin?: number;
                product?: PipelineProductEnum[];
                pipelineType?: PipelineTypeEnum[];
            },
        ) => {
            return async (areaToFetch?: any) => {
                const apiFilter = {
                    ...filter,
                    product:
                        filter.product && filter.product.length > 0
                            ? filter.product
                            : undefined,
                    pipelineType:
                        filter.pipelineType && filter.pipelineType.length > 0
                            ? filter.pipelineType
                            : undefined,
                };
                // Fetch page
                let response = await apiClient.mapPipelinesList({
                    ...apiFilter,
                    locationWithin: areaToFetch
                        ? JSON.stringify(areaToFetch)
                        : undefined,
                });
                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapPipelinesList({
                        ...apiFilter,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        cursor: parameters.get("cursor"),
                    });
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    // Pipeline data loader
    const coarseDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelineData, {
                    product: debouncedProduct,
                    pipelineType: debouncedPipelineType,
                    pipelineSizeMin: 2000,
                }),
            [fetchDataFn, debouncedProduct, debouncedPipelineType],
        ),
        zoomToStartFetching: 10,
        enabled,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });
    const detailsDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelineDetailsData, {
                    product: debouncedProduct,
                    pipelineType: debouncedPipelineType,
                    pipelineSizeMax: 2000,
                }),
            [fetchDataFn, debouncedProduct, debouncedPipelineType],
        ),
        zoomToStartFetching: 12,
        // Always let the coarse data loader run first
        enabled: enabled && !coarseDataLoader.loading,
        areaOnScreen,
        zoom: currentZoom,
        areaFilter: filterByArea,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setPipelineData([]);
        coarseDataLoader.resetState();
        detailsDataLoader.resetState();
    }, [debouncedPipelineType, debouncedProduct, filterByArea]);

    const layers = useMemo(() => {
        return PipelineV2Layers(
            currentZoom >= 11
                ? [...pipelineData, ...pipelineDetailsData]
                : pipelineData,
            overviewDataLoader.data,
            enabled,
            currentZoom,
        );
    }, [
        currentZoom,
        enabled,
        pipelineData,
        pipelineDetailsData,
        overviewDataLoader.data,
    ]);

    return {
        loading:
            detailsDataLoader.loading ||
            coarseDataLoader.loading ||
            (overviewDataLoader.isLoading && overviewDataLoader.isFetching),
        pipelines: enabled ? pipelineData : [],
        overviews: enabled ? overviewDataLoader.data : {},
        layers,
    };
};
