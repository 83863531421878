import {
    EventStatusEnum,
    InfraTypeEnum,
    PipelineCommodityEnum,
    PipelineSegmentTypeEnum,
} from "../../../apiClient/generated";
import { MapContext } from "../MapView";
import {
    compressToEncodedURIComponent,
    decompressFromEncodedURIComponent,
} from "lz-string";
import { MapSelectedContext } from "../state";

const replacer = function (key, value) {
    if (this[key] instanceof Date) {
        return { _isDate: true, value: this[key].toISOString() };
    }
    return value;
};

// Custom serialization/deserialization functions to deal with Date objects
export const serialize = <S>(state: S): string => {
    return JSON.stringify(state, replacer);
};

export const deserialize = <S>(state: string): S => {
    return JSON.parse(state, (key, value) => {
        if (value && value._isDate) {
            return new Date(value.value);
        }
        return value;
    });
};

/**
 * Serialize useful bits of the map state to string.
 *
 * We are using shorter names and a simpler structure to
 * limit the output size of the string.
 */
export const serializeMapContext = (
    mapContext: MapContext,
    selectedOnMap: MapSelectedContext,
    basemap?: string | number | null,
) => {
    return compressToEncodedURIComponent(
        serialize({
            lat: mapContext.mapState.viewstate?.latitude,
            lon: mapContext.mapState.viewstate?.longitude,
            z: mapContext.mapState.viewstate?.zoom,
            basemap,
            infrastructureFilter: {
                showInfrastructure:
                    mapContext.filters.infrastructure.showInfrastructure,
                infraTypeFilter:
                    mapContext.filters.infrastructure.infraTypeFilter,
                pipelineCommodity:
                    mapContext.filters.infrastructure.pipelineCommodity,
                pipelineSegmentType:
                    mapContext.filters.infrastructure.pipelineSegmentType,
            },
            emissionsFilters: {
                showEmissions: mapContext.filters.emissions.showEmissions,
                show3rdPartyEmissions:
                    mapContext.filters.emissions.show3rdPartyEmissions,
                showPublicData: mapContext.filters.emissions.showPublicData,
                showSelfReportedEmissions:
                    mapContext.filters.emissions.showSelfReportedEmissions,
                showEpaEmissions: mapContext.filters.emissions.showEpaEmissions,
                showPlumes: mapContext.filters.emissions.showPlumes,
                eventStatus: mapContext.filters.emissions.eventStatus,
                providerFilter: mapContext.filters.emissions.providerFilter,
                selfReportedProviderFilter:
                    mapContext.filters.emissions.selfReportedProviderFilter,
                startDateFilter: mapContext.filters.emissions.startDateFilter,
                endDateFilter: mapContext.filters.emissions.endDateFilter,
            },
            mapData: {
                selected: selectedOnMap,
            },
        }),
    );
};

/**
 * Deserializer for the map state.
 */
export const deserializeMapContext = (
    mapContextString: string,
): {
    lat: number;
    lon: number;
    z: number;
    basemap?: string | null;
    infrastructureFilter: {
        showInfrastructure: boolean;
        infraTypeFilter: InfraTypeEnum[];
        pipelineCommodity: PipelineCommodityEnum[];
        pipelineSegmentType: PipelineSegmentTypeEnum[];
    };
    emissionsFilters: {
        showEmissions: boolean;
        show3rdPartyEmissions: boolean;
        showSelfReportedEmissions: boolean;
        showEpaEmissions: boolean;
        showPlumes: boolean;
        showPublicData: boolean;
        eventStatus: EventStatusEnum[];
        providerFilter: string[];
        selfReportedProviderFilter: string[];
        startDateFilter: Date;
        endDateFilter: Date;
    };
    mapData: {
        selected?: MapSelectedContext;
    };
} => {
    return deserialize(decompressFromEncodedURIComponent(mapContextString));
};
