import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { MapDropdownMenu } from "../../ui/MapControls";
import { useContext } from "react";
import { MapContext } from "../../MapView";
import { CustomSwitch } from "../../../../ui/CustomSwitch";
import {
    InfraTypeEnum,
    PipelineCommodityEnum,
    PipelineProductEnum,
    PipelineSegmentTypeEnum,
    PipelineTypeEnum,
} from "../../../../apiClient/generated";
import { Checkbox, CheckboxWithMenu, MenuCategory } from "../../ui/Menu";
import { useAppSelector } from "../../../../hooks";

const PipelinesV2Settings = () => {
    const infrastructureFilters = useContext(MapContext).filters.infrastructure;

    return (
        <>
            <MenuCategory>
                <span className="mr-2">Product</span>
                <Checkbox
                    checked={infrastructureFilters.pipelineProduct.length !== 0}
                    onChange={() => {
                        if (infrastructureFilters.pipelineProduct.length == 0) {
                            infrastructureFilters.setPipelineProduct(
                                Object.values(PipelineProductEnum),
                            );
                        } else {
                            infrastructureFilters.setPipelineProduct([]);
                        }
                    }}
                />
            </MenuCategory>
            <div className="flex flex-col gap-1 mb-3">
                {Object.values(PipelineProductEnum).map((e) => (
                    <Checkbox
                        key={e}
                        label={e
                            .replace("_", " ")
                            .toLowerCase()
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                        checked={infrastructureFilters.pipelineProduct.includes(
                            e,
                        )}
                        onChange={() =>
                            infrastructureFilters.togglePipelineProduct(e)
                        }
                    />
                ))}
            </div>

            <MenuCategory>
                <span className="mr-2">Line Type</span>
                <Checkbox
                    checked={infrastructureFilters.pipelineType.length !== 0}
                    onChange={() => {
                        if (infrastructureFilters.pipelineType.length == 0) {
                            infrastructureFilters.setPipelineType(
                                Object.values(PipelineTypeEnum),
                            );
                        } else {
                            infrastructureFilters.setPipelineType([]);
                        }
                    }}
                />
            </MenuCategory>
            <div className="flex flex-col gap-1">
                {Object.values(PipelineTypeEnum).map((e) => (
                    <Checkbox
                        key={e}
                        label={e
                            .replace("_", " ")
                            .toLowerCase()
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                        checked={infrastructureFilters.pipelineType.includes(e)}
                        onChange={() =>
                            infrastructureFilters.togglePipelineType(e)
                        }
                    />
                ))}
            </div>
        </>
    );
};

const PipelinesV1Settings = () => {
    const infrastructureFilters = useContext(MapContext).filters.infrastructure;

    return (
        <>
            <MenuCategory>
                <span className="mr-2">Commodity filter</span>
                <Checkbox
                    checked={
                        infrastructureFilters.pipelineCommodity.length !== 0
                    }
                    onChange={() => {
                        if (
                            infrastructureFilters.pipelineCommodity.length == 0
                        ) {
                            infrastructureFilters.setPipelineCommodity(
                                Object.values(PipelineCommodityEnum),
                            );
                        } else {
                            infrastructureFilters.setPipelineCommodity([]);
                        }
                    }}
                />
            </MenuCategory>
            <div className="flex flex-col gap-1 mb-3">
                {Object.values(PipelineCommodityEnum).map((e) => (
                    <Checkbox
                        key={e}
                        label={e
                            .replace("_", " ")
                            .toLowerCase()
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                        checked={infrastructureFilters.pipelineCommodity.includes(
                            e,
                        )}
                        onChange={() =>
                            infrastructureFilters.togglePipelineCommodity(e)
                        }
                    />
                ))}
            </div>

            <MenuCategory>
                <span className="mr-2">Segment type filter</span>
                <Checkbox
                    checked={
                        infrastructureFilters.pipelineSegmentType.length !== 0
                    }
                    onChange={() => {
                        if (
                            infrastructureFilters.pipelineSegmentType.length ==
                            0
                        ) {
                            infrastructureFilters.setPipelineSegmentType(
                                Object.values(PipelineSegmentTypeEnum),
                            );
                        } else {
                            infrastructureFilters.setPipelineSegmentType([]);
                        }
                    }}
                />
            </MenuCategory>
            <div className="flex flex-col gap-1">
                {Object.values(PipelineSegmentTypeEnum).map((e) => (
                    <Checkbox
                        key={e}
                        label={e
                            .replace("_", " ")
                            .toLowerCase()
                            .replace(/\b\w/g, (l) => l.toUpperCase())}
                        checked={infrastructureFilters.pipelineSegmentType.includes(
                            e,
                        )}
                        onChange={() =>
                            infrastructureFilters.togglePipelineSegmentType(e)
                        }
                    />
                ))}
            </div>
        </>
    );
};

const PipelinesToggle = () => {
    const userflags = useAppSelector((state) => state.auth.flags);
    const infrastructureFilters = useContext(MapContext).filters.infrastructure;

    return (
        <CheckboxWithMenu
            checked={infrastructureFilters.infraTypeFilter.includes(
                InfraTypeEnum.Pipeline,
            )}
            onChange={() =>
                infrastructureFilters.toggleInfraTypeFilter(
                    InfraTypeEnum.Pipeline,
                )
            }
            label="Pipelines"
        >
            <div className="p-2">
                {userflags.includes("use_new_pipelines") ? (
                    <PipelinesV2Settings />
                ) : (
                    <PipelinesV1Settings />
                )}
                <hr className="my-2" />
                <div className="mt-2 text-xs italic">
                    Selecting all filters might not display all items in the
                    database. Disable the filters to show everything.
                </div>
            </div>
        </CheckboxWithMenu>
    );
};

export const InfrastructureControl = () => {
    const infrastructureFilters = useContext(MapContext).filters.infrastructure;

    return (
        <MapDropdownMenu
            button={
                <div className="flex h-8 w-56 text-sm items-center justify-between py-2 px-4 bg-white rounded">
                    Infrastructure
                    <ChevronDownIcon className="h-4" />
                </div>
            }
        >
            <div className="w-52 text-sm">
                <div className="flex p-2">
                    <CustomSwitch
                        size="sm"
                        key="enableInfrastructure"
                        checked={infrastructureFilters.showInfrastructure}
                        onChange={
                            infrastructureFilters.toggleShowInfrastructure
                        }
                    />
                    <label htmlFor="enableInfrastructure" className="ml-4">
                        Show on map
                    </label>
                </div>
                <hr />
                <p className="px-2 mt-3 mb-1 font-medium tracking-wide uppercase text-xs text-zinc-600">
                    Type of infrastructure
                </p>
                <div className="p-2 flex flex-col gap-2">
                    <PipelinesToggle />
                    {[InfraTypeEnum.Site, InfraTypeEnum.Equipment].map((e) => (
                        <Checkbox
                            key={e}
                            label={e
                                .replace("_", " ")
                                .toLowerCase()
                                .replace(/\b\w/g, (l) => l.toUpperCase())}
                            checked={infrastructureFilters.infraTypeFilter.includes(
                                e,
                            )}
                            onChange={() =>
                                infrastructureFilters.toggleInfraTypeFilter(e)
                            }
                        />
                    ))}
                </div>
            </div>
        </MapDropdownMenu>
    );
};
