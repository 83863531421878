import { EditableGeoJsonLayer } from "@deck.gl-community/editable-layers";
import {
    Dispatch,
    Reducer,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from "react";
import { DrawPolygonMode, ViewMode } from "@deck.gl-community/editable-layers";
import { emptyFeatureCollection } from "../../utils/geopatialUtils";
import {
    AerialImagesList,
    EmissionRecordMap,
    EventStatusEnum,
    InfraTypeEnum,
    InfrastructureMapList,
    MapEmissionRecordsListDataSourceEnum,
    MapPlumeImagesListDataSourceEnum,
    PipelineCommodityEnum,
    PipelineProductEnum,
    PipelineSegmentTypeEnum,
    PipelineTypeEnum,
    PlumeImage,
} from "../../apiClient/generated";
import * as turf from "@turf/turf";
import {
    useAppSelector,
    useDataProvidersApi,
    useMapApiClient,
} from "../../hooks";
import { DataPointMapWithBin, EmissionRecordMapWithBin } from "./CustomTypes";
import {
    AerialImagesLayer,
    InfrastructureLayer,
    PipelineLayer,
} from "./layers/infrastructure";
import {
    EmissionImagesLayer,
    EmissionRecordsLayer,
    PlumeOutlinesLayer,
} from "./layers/emissions";
import {
    CROSSHAIR_ICONS,
    EMISSION_COLORS,
    getBinForRate,
    MAP_ZOOM_SHOW_DETAILS,
} from "./constants";
import { useDebounce } from "@uidotdev/usehooks";
import { useQuery } from "@tanstack/react-query";
import { deserialize, serialize } from "./utils/state";
import { useMap } from "./hooks/mapState";
import { useMapData } from "./hooks/mapDataState";
import { useMapDataLoader } from "./hooks/dataLoader";

const selectedFeatureIndexes: number[] = [];

/**
 * Custom hook to handle drawing and area filtering on map.
 */
export const useDrawingAndFilteringOnMap = () => {
    const [isFiltering, setIsFiltering] = useState(false);
    const [isDrawing, setIsDrawing] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [drawnShape, setDrawnShape] = useState(emptyFeatureCollection);

    // Drawing on map
    const drawingLayer = useMemo(
        () =>
            new (EditableGeoJsonLayer as any)({
                id: "drawing-layer",
                data: drawnShape,
                mode: isDrawing ? DrawPolygonMode : ViewMode,
                selectedFeatureIndexes,
                pickable: isDrawing,
                modeConfig: {
                    enableSnapping: true,
                },
                onEdit: ({ updatedData, editType }) => {
                    if (editType === "addFeature") {
                        setIsDrawing(false);
                        setDrawnShape(updatedData);
                    }
                },
            }),
        [drawnShape, isDrawing],
    );

    const hasShape = useMemo(() => {
        return (
            drawnShape && drawnShape.features && drawnShape.features.length > 0
        );
    }, [drawnShape]);

    const filterByArea = useMemo(() => {
        if (hasShape && isFiltering) {
            return drawnShape.features.find(
                (i) =>
                    i.geometry.type == "Polygon" ||
                    i.geometry.type == "MultiPolygon",
            ).geometry;
        }
    }, [drawnShape, isFiltering, hasShape]);

    return {
        drawnShape,
        drawingLayer,
        isDrawing,
        isFiltering,
        setIsFiltering,
        startDrawing: useCallback(() => setIsDrawing(true), []),
        stopDrawing: useCallback((newShape) => {
            setIsDrawing(false);
            setDrawnShape(newShape || emptyFeatureCollection);
            setIsFiltering(false);
        }, []),
        hasShape,
        filterByArea,
        selectedFilters,
        setSelectedFilters,
    };
};

/**
 * Generic reducer / toggles
 */

// Helper to extract certain types of keys from array. Mostly typescript black magic.
type BooleanKeys<State> = {
    [Key in keyof State]: State[Key] extends boolean ? Key : never;
}[keyof State];

// Action type definitions.
interface ToggleFilterActionPayload<State, Element> {
    filterKey: keyof State & string;
    value: Element;
}

type Actions<State, K> =
    | { type: "TOGGLE_BOOLEAN"; payload: { key: BooleanKeys<State> } }
    | { type: "TOGGLE_FILTER"; payload: ToggleFilterActionPayload<State, K> }
    | { type: "SET_FILTER"; payload: { key: keyof State; value: K } };

// Reducer
const filterReducer = <State extends Record<string, any>, K>(
    state: State,
    action: Actions<State, K>,
): State => {
    switch (action.type) {
        case "TOGGLE_BOOLEAN":
            return {
                ...state,
                [action.payload.key]: !(state[action.payload.key] as boolean),
            };
        case "TOGGLE_FILTER": {
            const { filterKey, value } = action.payload;
            const filterableArray = state[filterKey] as K[];
            const updatedArray = filterableArray.includes(value)
                ? filterableArray.filter((i) => i !== value)
                : [...filterableArray, value];
            return {
                ...state,
                [filterKey]: updatedArray,
            };
        }
        case "SET_FILTER":
            return {
                ...state,
                [action.payload.key]: action.payload.value,
            };
        default:
            return state;
    }
};

/**
 * Custom persistent reducer code
 */

// Custom reducer that persists map settings to localStorage
function usePersistReducer<S>(
    reducer: Reducer<S, Actions<S, any>>,
    key: string,
    defaultState: S,
    replaceWith?: Partial<S>,
): [S, Dispatch<Actions<S, any>>] {
    const [state, dispatch] = useReducer(
        reducer,
        defaultState,
        (initial: S) => {
            const persisted = localStorage.getItem(key);
            if (replaceWith) {
                return {
                    ...initial,
                    ...replaceWith,
                };
            } else if (persisted) {
                return {
                    ...initial,
                    ...deserialize<S>(persisted),
                };
            }
            return initial;
        },
    );

    useEffect(() => {
        localStorage.setItem(key, serialize(state));
    }, [state, key]);

    return [state, dispatch];
}

/**
 * Custom hook to handle states related to infrastructure display control and filters
 */
type InfrastructureFiltersState = {
    showInfrastructure: boolean;
    infraTypeFilter: InfraTypeEnum[];
    pipelineCommodity: PipelineCommodityEnum[];
    pipelineSegmentType: PipelineSegmentTypeEnum[];
    pipelineProduct: PipelineProductEnum[];
    pipelineType: PipelineTypeEnum[];
};

const initialInfrastructureState: InfrastructureFiltersState = {
    showInfrastructure: true,
    infraTypeFilter: [InfraTypeEnum.Site, InfraTypeEnum.Equipment],
    pipelineCommodity: [],
    pipelineSegmentType: [],
    pipelineProduct: [PipelineProductEnum.NaturalGas, PipelineProductEnum.Ngl],
    pipelineType: [
        PipelineTypeEnum.Distribution,
        PipelineTypeEnum.Gathering,
        PipelineTypeEnum.Transmission,
    ],
};

export const useInfrastructureFilters = (
    initialState?: Partial<InfrastructureFiltersState>,
) => {
    const [state, dispatch] = usePersistReducer<InfrastructureFiltersState>(
        filterReducer<InfrastructureFiltersState, any>,
        "mapInfrastructureState",
        initialInfrastructureState,
        initialState,
    );

    return {
        ...state,
        toggleShowInfrastructure: () =>
            dispatch({
                type: "TOGGLE_BOOLEAN",
                payload: {
                    key: "showInfrastructure",
                },
            }),
        toggleInfraTypeFilter: (value: InfraTypeEnum) =>
            dispatch({
                type: "TOGGLE_FILTER",
                payload: {
                    filterKey: "infraTypeFilter",
                    value: value,
                },
            }),
        togglePipelineCommodity: (value: PipelineCommodityEnum) =>
            dispatch({
                type: "TOGGLE_FILTER",
                payload: {
                    filterKey: "pipelineCommodity",
                    value: value,
                },
            }),
        setPipelineCommodity: (value: PipelineCommodityEnum[]) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "pipelineCommodity",
                    value,
                },
            }),
        togglePipelineSegmentType: (value: PipelineSegmentTypeEnum) =>
            dispatch({
                type: "TOGGLE_FILTER",
                payload: {
                    filterKey: "pipelineSegmentType",
                    value: value,
                },
            }),
        setPipelineSegmentType: (value: PipelineSegmentTypeEnum[]) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "pipelineSegmentType",
                    value,
                },
            }),
        setPipelineType: (value: PipelineTypeEnum[]) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "pipelineType",
                    value,
                },
            }),
        togglePipelineType: (value: PipelineTypeEnum) =>
            dispatch({
                type: "TOGGLE_FILTER",
                payload: {
                    filterKey: "pipelineType",
                    value: value,
                },
            }),
        setPipelineProduct: (value: PipelineProductEnum[]) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "pipelineProduct",
                    value,
                },
            }),
        togglePipelineProduct: (value: PipelineProductEnum) =>
            dispatch({
                type: "TOGGLE_FILTER",
                payload: {
                    filterKey: "pipelineProduct",
                    value: value,
                },
            }),
    };
};

/**
 * Custom hook to handle states related to emissions display control and filters
 */
type EmissionFiltersState = {
    showEmissions: boolean;
    show3rdPartyEmissions: boolean;
    showSelfReportedEmissions: boolean;
    showEpaEmissions: boolean;
    showPlumes: boolean;
    showPublicData: boolean;
    eventStatus: EventStatusEnum[];
    providerFilter: string[];
    selfReportedProviderFilter: string[];
    startDateFilter: Date;
    endDateFilter: Date;
};

const initialEmissionState: EmissionFiltersState = {
    showEmissions: true,
    show3rdPartyEmissions: true,
    showSelfReportedEmissions: true,
    showEpaEmissions: true,
    showPlumes: true,
    showPublicData: true,
    eventStatus: [],
    providerFilter: [],
    selfReportedProviderFilter: [],
    startDateFilter: new Date(new Date().setMonth(new Date().getMonth() - 12)),
    endDateFilter: new Date(),
};

export const useEmissionFilters = (initialState?: EmissionFiltersState) => {
    // Reducer and helper methods to store and change filter states
    const [state, dispatch] = usePersistReducer<EmissionFiltersState>(
        filterReducer<EmissionFiltersState, any>,
        "mapEmissionState",
        initialEmissionState,
        initialState,
    );

    // Clear event filters
    useEffect(() => {
        if (state.eventStatus.length > 0) {
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "eventStatus",
                    value: [],
                },
            });
        }
    }, []);

    // Fetch data providers
    const dataProvidersApiClient = useDataProvidersApi();
    const thirdPartyDataProvidersQuery = useQuery({
        queryKey: ["thirdPartyDataProviders"],
        queryFn: async () => {
            return await dataProvidersApiClient.dataProvidersList({
                hasData: true,
            });
        },
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (thirdPartyDataProvidersQuery.data?.results.length > 0) {
            if (state.providerFilter.length < 1) {
                dispatch({
                    type: "SET_FILTER",
                    payload: {
                        key: "providerFilter",
                        value: thirdPartyDataProvidersQuery.data.results.map(
                            (i) => i.id,
                        ),
                    },
                });
            }
        }
    }, [thirdPartyDataProvidersQuery]);

    const selfReportedDataProvidersQuery = useQuery({
        queryKey: ["selfReportedDataProviders"],
        queryFn: async () => {
            return await dataProvidersApiClient.dataProvidersList({
                dataSource: ["SELF_REPORTED"],
            });
        },
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (selfReportedDataProvidersQuery.data?.results.length > 0) {
            if (state.selfReportedProviderFilter.length < 1) {
                dispatch({
                    type: "SET_FILTER",
                    payload: {
                        key: "selfReportedProviderFilter",
                        value: selfReportedDataProvidersQuery.data.results.map(
                            (i) => i.id,
                        ),
                    },
                });
            }
        }
    }, [selfReportedDataProvidersQuery]);

    return {
        ...state,
        toggleShowEmissions: () =>
            dispatch({
                type: "TOGGLE_BOOLEAN",
                payload: {
                    key: "showEmissions",
                },
            }),
        toggleShowPlumes: () =>
            dispatch({
                type: "TOGGLE_BOOLEAN",
                payload: {
                    key: "showPlumes",
                },
            }),
        toggleShow3rdPartyEmissions: () =>
            dispatch({
                type: "TOGGLE_BOOLEAN",
                payload: {
                    key: "show3rdPartyEmissions",
                },
            }),
        toggleShowSelfReportedEmissions: () =>
            dispatch({
                type: "TOGGLE_BOOLEAN",
                payload: {
                    key: "showSelfReportedEmissions",
                },
            }),
        toggleShowEpaEmissions: () =>
            dispatch({
                type: "TOGGLE_BOOLEAN",
                payload: {
                    key: "showEpaEmissions",
                },
            }),
        toggleShowPublicData: () =>
            dispatch({
                type: "TOGGLE_BOOLEAN",
                payload: {
                    key: "showPublicData",
                },
            }),
        setStartDateFilter: (value: Date) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "startDateFilter",
                    value,
                },
            }),
        setEndDateFilter: (value: Date) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "endDateFilter",
                    value,
                },
            }),
        setEventStatus: (value: EventStatusEnum[]) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "eventStatus",
                    value,
                },
            }),
        toggleEventStatus: (value: EventStatusEnum) =>
            dispatch({
                type: "TOGGLE_FILTER",
                payload: {
                    filterKey: "eventStatus",
                    value,
                },
            }),
        // Provider data and setters
        toggleProviderFilter: (value: string) =>
            dispatch({
                type: "TOGGLE_FILTER",
                payload: {
                    filterKey: "providerFilter",
                    value,
                },
            }),
        setProviderFilter: (value: string[]) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "providerFilter",
                    value,
                },
            }),
        // Provider data and setters
        toggleSelfReportedProviderFilter: (value: string) =>
            dispatch({
                type: "TOGGLE_FILTER",
                payload: {
                    filterKey: "selfReportedProviderFilter",
                    value,
                },
            }),
        setSelfReportedProviderFilter: (value: string[]) =>
            dispatch({
                type: "SET_FILTER",
                payload: {
                    key: "selfReportedProviderFilter",
                    value,
                },
            }),
        dataProviders: thirdPartyDataProvidersQuery.data?.results || [],
        selfReportedDataProviders:
            selfReportedDataProvidersQuery.data?.results || [],
    };
};

/**
 * Infrastructure data: custom hook that loads infrastructure-related
 * data (points, geometries and aerial images) based on filter values.
 *
 * Sites and other equipment are returned separately since they are
 * rendered and fetched in different zoom levels.
 */
export const useInfrastructureMapData = (
    currentZoom: number,
    areaOnScreen: any,
    infraTypes: InfraTypeEnum[],
    pipelineCommodity: PipelineCommodityEnum[],
    pipelineSegmentType: PipelineSegmentTypeEnum[],
    // FIXME: Type this properly
    filterByArea?: any,
) => {
    const apiClient = useMapApiClient();
    const debouncedPipelineCommodity = useDebounce(pipelineCommodity, 400);
    const debouncedPipelineSegmentType = useDebounce(pipelineSegmentType, 400);
    // Overview (zoomed out data)
    const [sites, setSites] = useState<InfrastructureMapList[]>([]);
    const [pipelines, setPipelines] = useState<InfrastructureMapList[]>([]);
    // Detail (zoomed in data)
    const [equipment, setEquipment] = useState<InfrastructureMapList[]>([]);
    const [aerialImageData, setAerialImageData] = useState<AerialImagesList[]>(
        [],
    );

    const fetchDataFn = useCallback(
        (
            setter: React.Dispatch<
                React.SetStateAction<InfrastructureMapList[]>
            >,
            filter: {
                infraType: InfraTypeEnum[];
                pipelineDiameterMax?: number;
                pipelineDiameterMin?: number;
                pipelineSizeMax?: number;
                pipelineSizeMin?: number;
                pipelineCommodity?: PipelineCommodityEnum[];
                pipelineSegmentType?: PipelineSegmentTypeEnum[];
            },
        ) => {
            return async (areaToFetch?: any) => {
                const apiFilter = {
                    ...filter,
                    pipelineCommodity:
                        filter.pipelineCommodity &&
                        filter.pipelineCommodity.length > 0
                            ? filter.pipelineCommodity
                            : undefined,
                    pipelineSegmentType:
                        filter.pipelineSegmentType &&
                        filter.pipelineSegmentType.length > 0
                            ? filter.pipelineSegmentType
                            : undefined,
                };
                // Fetch page
                let response = await apiClient.mapInfrastructureList({
                    ...apiFilter,
                    locationWithin: areaToFetch
                        ? JSON.stringify(areaToFetch)
                        : undefined,
                });
                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapInfrastructureList({
                        ...apiFilter,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        cursor: parameters.get("cursor"),
                    });
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    const fetchAerialImagesDataFn = useCallback(
        (setter: React.Dispatch<React.SetStateAction<AerialImagesList[]>>) => {
            return async (areaToFetch?: any) => {
                // Fetch page
                let response = await apiClient.mapAerialImagesList({
                    locationWithin: areaToFetch
                        ? JSON.stringify(areaToFetch)
                        : undefined,
                });
                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapAerialImagesList({
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                        cursor: parameters.get("cursor"),
                    });
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    // Load sites first
    const siteDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setSites, {
                    infraType: [InfraTypeEnum.Site],
                }),
            [fetchDataFn],
        ),
        zoomToStartFetching: 1,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });

    // Data loader
    const equipmentDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setEquipment, {
                    infraType: [
                        InfraTypeEnum.EquipmentGroup,
                        InfraTypeEnum.Equipment,
                    ],
                }),
            [fetchDataFn],
        ),
        zoomToStartFetching: 12,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });

    // Aerial images data first
    const aerialImagesDataLoader = useMapDataLoader({
        loadDataCallback: fetchAerialImagesDataFn(setAerialImageData),
        zoomToStartFetching: 12,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });

    // Tiered pipeline loader
    // FIXME: there's got to be a better way of doing this, if there's enough time, investigate.
    const largePipelinesDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelines, {
                    infraType: [InfraTypeEnum.Pipeline],
                    pipelineSizeMin: 10000,
                    pipelineCommodity: pipelineCommodity,
                    pipelineSegmentType: pipelineSegmentType,
                }),
            [fetchDataFn, pipelineCommodity, pipelineSegmentType],
        ),
        zoomToStartFetching: 3,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });
    const mediumPipelinesDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelines, {
                    infraType: [InfraTypeEnum.Pipeline],
                    pipelineSizeMin: 5000,
                    pipelineSizeMax: 10000,
                    pipelineCommodity: pipelineCommodity,
                    pipelineSegmentType: pipelineSegmentType,
                }),
            [fetchDataFn, pipelineCommodity, pipelineSegmentType],
        ),
        zoomToStartFetching: 7,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });
    const pipelinesDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelines, {
                    infraType: [InfraTypeEnum.Pipeline],
                    pipelineSizeMax: 5000,
                    pipelineSizeMin: 500,
                    pipelineCommodity: pipelineCommodity,
                    pipelineSegmentType: pipelineSegmentType,
                }),
            [fetchDataFn, pipelineCommodity, pipelineSegmentType],
        ),
        zoomToStartFetching: 10,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });
    const reallySmallPipelinesDataLoader = useMapDataLoader({
        loadDataCallback: useMemo(
            () =>
                fetchDataFn(setPipelines, {
                    infraType: [InfraTypeEnum.Pipeline],
                    pipelineSizeMax: 500,
                    pipelineCommodity: pipelineCommodity,
                    pipelineSegmentType: pipelineSegmentType,
                }),
            [fetchDataFn, pipelineCommodity, pipelineSegmentType],
        ),
        zoomToStartFetching: 12,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setPipelines([]);
        largePipelinesDataLoader.resetState();
        mediumPipelinesDataLoader.resetState();
        pipelinesDataLoader.resetState();
        reallySmallPipelinesDataLoader.resetState();
    }, [debouncedPipelineCommodity, debouncedPipelineSegmentType]);

    // Memoize items shown on map based on loaded data,
    // zoom level and filter parameters.
    const itemsShown = useMemo(() => {
        const data = [
            // Only return sites if they are enabled.
            ...(infraTypes.includes(InfraTypeEnum.Site) ? sites : []),
            // Only return equipment if zoom > MAP_ZOOM_SHOW_DETAILS and they are enabled.
            ...(currentZoom > MAP_ZOOM_SHOW_DETAILS
                ? equipment.filter((e) => infraTypes.includes(e.infraType))
                : []),
        ];
        if (filterByArea) {
            return data.filter((i) => {
                return turf.booleanPointInPolygon(
                    turf.point(i.location.coordinates),
                    filterByArea,
                );
            });
        }
        return data;
    }, [infraTypes, currentZoom, sites, equipment, filterByArea]);

    const shownPipelines = useMemo(() => {
        if (filterByArea) {
            return pipelines.filter((i) => {
                return turf.booleanIntersects(i.pipelineShape, filterByArea);
            });
        }
        return pipelines;
    }, [pipelines, filterByArea]);

    // Memoize items shown on map based on loaded data,
    // zoom level and filter parameters.
    const aerialImages = useMemo(() => {
        if (currentZoom > MAP_ZOOM_SHOW_DETAILS) {
            if (filterByArea) {
                return aerialImageData.filter((i) => {
                    return turf.booleanPointInPolygon(
                        i.center?.coordinates,
                        filterByArea,
                    );
                });
            }
            return aerialImageData;
        }
        return [];
    }, [infraTypes, currentZoom, sites, equipment]);

    return {
        loading:
            equipmentDataLoader.loading ||
            siteDataLoader.loading ||
            aerialImagesDataLoader.loading ||
            largePipelinesDataLoader.loading ||
            mediumPipelinesDataLoader.loading ||
            pipelinesDataLoader.loading ||
            reallySmallPipelinesDataLoader.loading,
        infrastructure: itemsShown,
        pipelines: infraTypes.includes(InfraTypeEnum.Pipeline)
            ? shownPipelines
            : [],
        aerialImages,
    };
};

/**
 * Emission data: custom hook that loads emissions-related
 * data (points and plume images) based on filter values.
 */
export const useEmissionRecordsMapData = (
    currentZoom: number,
    areaOnScreen: any,
    filters: {
        detectionDateRangeAfter?: Date;
        detectionDateRangeBefore?: Date;
        eventStatus?: EventStatusEnum[];
        provider?: string[];
        dataSource?: MapPlumeImagesListDataSourceEnum[];
    },
    // FIXME: Type this properly
    filterByArea?: any,
) => {
    const apiClient = useMapApiClient();
    const debouncedFilters = useDebounce(filters, 400);
    const [emissions, setEmissions] = useState<EmissionRecordMapWithBin[]>([]);
    const [plumeImages, setPlumeImages] = useState<PlumeImage[]>([]);

    const fetchDataFn = useCallback(
        (
            setter: React.Dispatch<
                React.SetStateAction<EmissionRecordMapWithBin[]>
            >,
            filters: {
                detectionDateRangeAfter?: Date;
                detectionDateRangeBefore?: Date;
                eventStatus?: EventStatusEnum[];
                provider?: string[];
                dataSource?: MapEmissionRecordsListDataSourceEnum[];
            },
        ) => {
            return async (areaToFetch?: any, signal?: AbortSignal) => {
                // Apply any filter operations before passing them to API client
                const apiFilters = {
                    ...filters,
                    provider:
                        filters.provider && filters.provider.length > 0
                            ? filters.provider
                            : undefined,
                    // If no event status is selected then ignore filter
                    eventStatus:
                        filters.eventStatus && filters.eventStatus.length > 0
                            ? filters.eventStatus
                            : undefined,
                };
                // If no data source is selected or
                // if third party is selected but no provider is selected
                const shouldSkipLoading =
                    !filters.dataSource?.length ||
                    (filters.dataSource?.indexOf("THIRD_PARTY") >= 0 &&
                        !filters.provider?.length);

                if (shouldSkipLoading) {
                    setter([]);
                    return;
                }

                // Fetch page
                let response = await apiClient.mapEmissionRecordsList(
                    {
                        ...apiFilters,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                    },
                    {
                        signal,
                    },
                );
                setter((data) =>
                    data.concat(
                        response.results.map((i) => {
                            return {
                                ...i,
                                mapDotSize: getBinForRate(i.detectedRate / 1000)
                                    .size,
                            };
                        }),
                    ),
                );
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapEmissionRecordsList(
                        {
                            ...apiFilters,
                            locationWithin: areaToFetch
                                ? JSON.stringify(areaToFetch)
                                : undefined,
                            cursor: parameters.get("cursor"),
                        },
                        {
                            signal,
                        },
                    );
                    setter((data) =>
                        data.concat(
                            response.results.map((i) => {
                                return {
                                    ...i,
                                    mapDotSize: getBinForRate(
                                        i.detectedRate / 1000,
                                    ).size,
                                };
                            }),
                        ),
                    );
                }
            };
        },
        [apiClient],
    );

    const fetchPlumeImagesDataFn = useCallback(
        (
            setter: React.Dispatch<React.SetStateAction<PlumeImage[]>>,
            filters: {
                detectionDateRangeAfter?: Date;
                detectionDateRangeBefore?: Date;
                eventStatus?: EventStatusEnum[];
                provider?: string[];
                dataSource?: MapPlumeImagesListDataSourceEnum[];
            },
        ) => {
            return async (areaToFetch?: any, signal?: AbortSignal) => {
                // Apply any filter operations before passing them to API client
                const apiFilters = {
                    ...filters,
                    provider:
                        filters.provider && filters.provider.length > 0
                            ? filters.provider
                            : undefined,
                    // If no event status is selected then ignore filter
                    eventStatus:
                        filters.eventStatus && filters.eventStatus.length > 0
                            ? filters.eventStatus
                            : undefined,
                };
                // If no data source is selected or
                // if third party is selected but no provider is selected
                const shouldSkipLoading =
                    !filters.dataSource?.length ||
                    (filters.dataSource?.indexOf("THIRD_PARTY") >= 0 &&
                        !filters.provider?.length);

                if (shouldSkipLoading) {
                    setter([]);
                    return;
                }

                // Now that it's allowed to fetch even if no provider is selected
                // we need to modify the filter so that it doesn't include provider
                // as it will fail validation on the backend
                const modifiedFilters = { ...apiFilters };
                if (!filters.provider?.length) {
                    modifiedFilters.provider = undefined;
                }

                // Fetch page
                let response = await apiClient.mapPlumeImagesList(
                    {
                        ...modifiedFilters,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                    },
                    {
                        signal,
                    },
                );
                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapPlumeImagesList(
                        {
                            ...apiFilters,
                            locationWithin: areaToFetch
                                ? JSON.stringify(areaToFetch)
                                : undefined,
                            cursor: parameters.get("cursor"),
                        },
                        {
                            signal,
                        },
                    );
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    // Load emissions
    const emissionsDataLoader = useMapDataLoader({
        loadDataCallback: fetchDataFn(setEmissions, debouncedFilters),
        zoomToStartFetching: 1,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });

    // Load plume images
    const plumeImagesDataLoader = useMapDataLoader({
        loadDataCallback: fetchPlumeImagesDataFn(
            setPlumeImages,
            debouncedFilters,
        ),
        zoomToStartFetching: 12,
        enabled: true,
        areaOnScreen,
        zoom: currentZoom,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setEmissions([]);
        setPlumeImages([]);
        emissionsDataLoader.resetState();
        plumeImagesDataLoader.resetState();
    }, [debouncedFilters]);

    // Filter emissions using filterByArea
    const shownEmissions = useMemo(() => {
        if (filterByArea) {
            return emissions.filter((i) => {
                return turf.booleanPointInPolygon(
                    i.location?.coordinates,
                    filterByArea,
                );
            });
        }
        return emissions;
    }, [emissions, filterByArea]);

    // Filter plumeImages using filterByArea
    const shownPlumeImages = useMemo(() => {
        if (filterByArea) {
            return plumeImages.filter((plumeImage) => {
                return turf.booleanPointInPolygon(
                    plumeImage.center?.coordinates,
                    filterByArea,
                );
            });
        }
        return plumeImages;
    }, [plumeImages, filterByArea]);

    return {
        loading: emissionsDataLoader.loading || plumeImagesDataLoader.loading,
        emissions: shownEmissions,
        plumeImages: shownPlumeImages,
    };
};

export const usePublicEmissionMapData = (
    currentZoom: number,
    areaOnScreen: any,
    enabled: boolean,
    filters: {
        detectionDateRangeAfter?: Date;
        detectionDateRangeBefore?: Date;
        provider?: string[];
        dataSource?: MapPlumeImagesListDataSourceEnum[];
    },
    filterByArea?: any,
) => {
    const apiClient = useMapApiClient();
    const flags = useAppSelector((state) => state.auth.flags);
    const debouncedFilters = useDebounce(filters, 400);
    const [emissions, setEmissions] = useState<DataPointMapWithBin[]>([]);
    const [plumeImages, setPlumeImages] = useState<PlumeImage[]>([]);

    const fetchDataFn = useCallback(
        (
            setter: React.Dispatch<React.SetStateAction<DataPointMapWithBin[]>>,
            filters: {
                detectionDateRangeAfter?: Date;
                detectionDateRangeBefore?: Date;
                provider?: string[];
                dataSource?: MapEmissionRecordsListDataSourceEnum[];
            },
        ) => {
            return async (areaToFetch?: any, signal?: AbortSignal) => {
                // Apply any filter operations before passing them to API client
                const apiFilters = {
                    ...filters,
                    provider:
                        filters.provider && filters.provider.length > 0
                            ? filters.provider
                            : undefined,
                };
                // If no data source is selected or
                // if third party is selected but no provider is selected
                const shouldSkipLoading =
                    !filters.dataSource?.length ||
                    (filters.dataSource?.indexOf("THIRD_PARTY") >= 0 &&
                        !filters.provider?.length);

                if (shouldSkipLoading) {
                    setter([]);
                    return;
                }

                // Fetch page
                let response = await apiClient.mapPublicEmissionsList(
                    {
                        ...apiFilters,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                    },
                    {
                        signal,
                    },
                );
                setter((data) =>
                    data.concat(
                        response.results.map((i) => {
                            return {
                                ...i,
                                mapDotSize: getBinForRate(i.detectedRate / 1000)
                                    .size,
                            };
                        }),
                    ),
                );
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapPublicEmissionsList(
                        {
                            ...apiFilters,
                            locationWithin: areaToFetch
                                ? JSON.stringify(areaToFetch)
                                : undefined,
                            cursor: parameters.get("cursor"),
                        },
                        {
                            signal,
                        },
                    );
                    setter((data) =>
                        data.concat(
                            response.results.map((i) => {
                                return {
                                    ...i,
                                    mapDotSize: getBinForRate(
                                        i.detectedRate / 1000,
                                    ).size,
                                };
                            }),
                        ),
                    );
                }
            };
        },
        [apiClient],
    );

    const fetchPlumeImagesDataFn = useCallback(
        (
            setter: React.Dispatch<React.SetStateAction<PlumeImage[]>>,
            filters: {
                detectionDateRangeAfter?: Date;
                detectionDateRangeBefore?: Date;
                provider?: string[];
                dataSource?: MapPlumeImagesListDataSourceEnum[];
            },
        ) => {
            return async (areaToFetch?: any, signal?: AbortSignal) => {
                // Apply any filter operations before passing them to API client
                const apiFilters = {
                    ...filters,
                    provider:
                        filters.provider && filters.provider.length > 0
                            ? filters.provider
                            : undefined,
                };
                // If no data source is selected or
                // if third party is selected but no provider is selected
                const shouldSkipLoading =
                    !filters.dataSource?.length ||
                    (filters.dataSource?.indexOf("THIRD_PARTY") >= 0 &&
                        !filters.provider?.length);

                if (shouldSkipLoading) {
                    setter([]);
                    return;
                }

                // Now that it's allowed to fetch even if no provider is selected
                // we need to modify the filter so that it doesn't include provider
                // as it will fail validation on the backend
                const modifiedFilters = { ...apiFilters };
                if (!filters.provider?.length) {
                    modifiedFilters.provider = undefined;
                }

                // Fetch page
                let response = await apiClient.mapPublicPlumeImagesList(
                    {
                        ...modifiedFilters,
                        locationWithin: areaToFetch
                            ? JSON.stringify(areaToFetch)
                            : undefined,
                    },
                    {
                        signal,
                    },
                );
                setter((data) => data.concat(response.results));
                // If more pages exist, fetch those too.
                while (response.next) {
                    const url = new URL(response.next);
                    const parameters = new URLSearchParams(url.search);
                    response = await apiClient.mapPublicPlumeImagesList(
                        {
                            ...apiFilters,
                            locationWithin: areaToFetch
                                ? JSON.stringify(areaToFetch)
                                : undefined,
                            cursor: parameters.get("cursor"),
                        },
                        {
                            signal,
                        },
                    );
                    setter((data) => data.concat(response.results));
                }
            };
        },
        [apiClient],
    );

    // Load emissions
    const emissionsDataLoader = useMapDataLoader({
        loadDataCallback: fetchDataFn(setEmissions, debouncedFilters),
        zoomToStartFetching: 1,
        enabled: enabled && flags.includes("show_public_emissions"),
        areaOnScreen,
        zoom: currentZoom,
    });

    // Load plume images
    const plumeImagesDataLoader = useMapDataLoader({
        loadDataCallback: fetchPlumeImagesDataFn(
            setPlumeImages,
            debouncedFilters,
        ),
        zoomToStartFetching: 12,
        enabled: enabled && flags.includes("show_public_emissions"),
        areaOnScreen,
        zoom: currentZoom,
    });

    // Reset all data loading when filters change and
    // abort current requests.
    useEffect(() => {
        setEmissions([]);
        setPlumeImages([]);
        emissionsDataLoader.resetState();
        plumeImagesDataLoader.resetState();
    }, [debouncedFilters]);

    // Filter emissions using filterByArea
    const shownEmissions = useMemo(() => {
        if (filterByArea) {
            return emissions.filter((i) => {
                return turf.booleanPointInPolygon(
                    i.location?.coordinates,
                    filterByArea,
                );
            });
        }
        return emissions;
    }, [emissions, filterByArea]);

    // Filter plumeImages using filterByArea
    const shownPlumeImages = useMemo(() => {
        if (filterByArea) {
            return plumeImages.filter((plumeImage) => {
                return turf.booleanPointInPolygon(
                    plumeImage.center?.coordinates,
                    filterByArea,
                );
            });
        }
        return plumeImages;
    }, [plumeImages, filterByArea]);

    return {
        loading: emissionsDataLoader.loading || plumeImagesDataLoader.loading,
        emissions: shownEmissions,
        plumeImages: shownPlumeImages,
    };
};

/**
 * Custom hook to display layers based on filters and current viewstate.
 */
export const useAerscapeLayers = (
    infrastructureData: InfrastructureMapList[],
    pipelineData: InfrastructureMapList[],
    showInfrastructure: boolean,
    emissionRecordData: {
        operatorProvided: EmissionRecordMap[];
        thirdParty: EmissionRecordMap[];
        thirdPartyPublic: DataPointMapWithBin[];
        epa: EmissionRecordMap[];
        epaPublic: DataPointMapWithBin[];
    },
    showEmissions: boolean,
    showPlumes: boolean,
    showPublicData: boolean,
    aerialImageData: AerialImagesList[],
    plumeImagesData: PlumeImage[],
    pipelineLayers?: any[],
) => {
    const {
        debounced: { viewState },
    } = useMap("mainMap");
    const zoom = useMemo(() => viewState?.zoom, [viewState]);

    // Get map selection data
    const {
        selectedContext,
        mapSettings: { plumeOpacity },
    } = useMapData("mainMap");

    const aerialImagesLayer = useMemo(() => {
        return AerialImagesLayer(aerialImageData, showInfrastructure, zoom);
    }, [aerialImageData, showInfrastructure, zoom]);

    const emissionPlumesLayer = useMemo(() => {
        if (selectedContext.relatedPlume === null) {
            return [];
        }
        return EmissionImagesLayer(
            selectedContext.relatedPlume
                ? plumeImagesData.filter(
                      (i) => i.id === selectedContext.relatedPlume,
                  )
                : plumeImagesData,
            showEmissions && showPlumes,
            zoom,
            plumeOpacity,
        );
    }, [
        plumeImagesData,
        showEmissions,
        showPlumes,
        zoom,
        selectedContext,
        plumeOpacity,
    ]);

    const plumeOutlines = useMemo(
        () =>
            emissionRecordData.thirdParty
                .filter(
                    (emission) =>
                        emission.geometry !== null &&
                        (!(
                            selectedContext.emissionRecordId ||
                            selectedContext.dataPointId
                        ) ||
                            selectedContext.emissionRecordId === emission.id),
                )
                .map((emission) => emission.geometry),
        [emissionRecordData.thirdParty, selectedContext],
    );

    const plumeOutlinesLayer = useMemo(() => {
        return PlumeOutlinesLayer(
            plumeOutlines,
            showEmissions && showPlumes,
            zoom,
            plumeOpacity,
        );
    }, [plumeOutlines, showEmissions, showPlumes, zoom, plumeOpacity]);

    const infrastructureLayer = useMemo(() => {
        return InfrastructureLayer(
            infrastructureData,
            showInfrastructure,
            zoom,
        );
    }, [infrastructureData, showInfrastructure, zoom]);

    const pipelineLayer = useMemo(() => {
        return PipelineLayer(pipelineData, showInfrastructure, zoom);
    }, [pipelineData, showInfrastructure, zoom]);

    const emissionThirdPartyPublicLayer = useMemo(() => {
        return EmissionRecordsLayer(
            emissionRecordData.thirdPartyPublic,
            showEmissions && showPublicData,
            zoom,
            EMISSION_COLORS.thirdPartyPublic,
            CROSSHAIR_ICONS.thirdPartyPublic,
            "datapointThirdPartyPublic",
        );
    }, [emissionRecordData, showEmissions, showPublicData, zoom]);

    const emissionEpaPublicLayer = useMemo(() => {
        return EmissionRecordsLayer(
            emissionRecordData.epaPublic,
            showEmissions && showPublicData,
            zoom,
            EMISSION_COLORS.epaPublic,
            CROSSHAIR_ICONS.epaPublic,
            "datapointEpaPublic",
        );
    }, [emissionRecordData, showEmissions, showPublicData, zoom]);

    const emissionSelfReportedLayer = useMemo(() => {
        return EmissionRecordsLayer(
            emissionRecordData.operatorProvided,
            showEmissions,
            zoom,
            EMISSION_COLORS.operatorProvided,
            CROSSHAIR_ICONS.operatorProvided,
            "emissionsOperatorProvided",
        );
    }, [emissionRecordData, showEmissions, zoom]);

    const emissionThirdPartyLayer = useMemo(() => {
        return EmissionRecordsLayer(
            emissionRecordData.thirdParty,
            showEmissions,
            zoom,
            EMISSION_COLORS.thirdParty,
            CROSSHAIR_ICONS.thirdParty,
            "emissionsThirdParty",
        );
    }, [emissionRecordData, showEmissions, zoom]);

    const emissionEpaLayer = useMemo(() => {
        return EmissionRecordsLayer(
            emissionRecordData.epa,
            showEmissions,
            zoom,
            EMISSION_COLORS.epa,
            CROSSHAIR_ICONS.epa,
            "emissionsEpa",
        );
    }, [emissionRecordData, showEmissions, zoom]);

    return [
        aerialImagesLayer,
        plumeOutlinesLayer,
        emissionPlumesLayer,
        pipelineLayer,
        ...(pipelineLayers || []),
        infrastructureLayer,
        emissionThirdPartyPublicLayer,
        emissionEpaPublicLayer,
        emissionSelfReportedLayer,
        emissionThirdPartyLayer,
        emissionEpaLayer,
    ];
};
