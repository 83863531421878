import { useAtom } from "jotai";
import { useCallback } from "react";
import { MapDataState, mapDataStateFamily, MapSelectedContext } from "../state";

export const useMapData = (
    // FIXME: to avoid having to pass mapId everytime, accept either this input or a context (?)
    mapId: string,
    initialState?: Partial<MapDataState>,
) => {
    // Atom to hold map state here.
    const [mapDataState, setMapDataState] = useAtom(
        mapDataStateFamily({ mapId: mapId, initialState }),
    );

    // Setters for map selections
    const setSelectedContext = useCallback(
        (selectedContext: MapSelectedContext) => {
            setMapDataState((ds) => ({
                ...ds,
                selectedContext,
            }));
        },
        [setMapDataState],
    );

    return {
        ...mapDataState,
        setMapDataState,
        setSelectedContext,
    };
};
