import { Combobox, Listbox } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Fragment, ReactNode, useState } from "react";

interface SearchInputProps {
    value: string;
    placeholder: string;
    onChange: (newValue: string) => void;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    autoFocus?: boolean;
    className?: string;
    containerClassName?: string;
}

export const SearchInput = (props: SearchInputProps) => {
    return (
        <div
            className={`
            ${props.containerClassName || ""}
            relative h-9
        `}
        >
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                <MagnifyingGlassIcon className="w-5 h-5 text-ae-blue-900" />
            </div>
            <input
                type="search"
                id="default-search"
                className={`
                    ${props.className || ""}
                    block ps-10 text-sm rounded-md placeholder:text-ae-gray-300 border border-ae-gray-200
                    w-full
                `}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                onKeyUp={props.onKeyUp}
                autoFocus={props.autoFocus}
            />
        </div>
    );
};

type Item = {
    id: string | number;
    label: string;
};

interface ListboxProps {
    selected: Item[];
    setSelected: (selected: Item[]) => void;
    options: Item[];
}

export const ListBox = (props: ListboxProps) => {
    return (
        <Listbox value={props.selected} onChange={props.setSelected} multiple>
            <Listbox.Button className="w-full rounded p-2 text-left border h-8 border-ae-slate-600 line-clamp-1 whitespace-nowrap">
                {props.selected.map((i) => i.label).join(", ")}
            </Listbox.Button>
            <div className="relative text-sm">
                <Listbox.Options className="top-1 z-0 flex flex-col absolute bg-white rounded w-full border border-ae-slate-600 drop-shadow-md">
                    {props.options.map((i) => (
                        <Listbox.Option
                            className="p-2 flex cursor-pointer bg-white rounded hover:bg-neutral-100"
                            key={i.id}
                            value={i}
                        >
                            {({ selected }) => (
                                <>
                                    <span
                                        className={`flex-grow ${
                                            selected
                                                ? "font-medium"
                                                : "font-normal"
                                        }`}
                                    >
                                        {i.label}
                                    </span>
                                    {selected ? (
                                        <CheckIcon className="h-5 mr-2" />
                                    ) : null}
                                </>
                            )}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
};

interface FilteringDropdownProps {
    value: number | string | null;
    onChange: (value: number | string) => void;
    emptyValue?: string;
    options: {
        id: string | number;
        displayName: string;
        searchString: string;
    }[];
    extraActions?: {
        icon: ReactNode;
        title: string;
        action: () => void;
    }[];
}

export const FilteringDropdown = (props: FilteringDropdownProps) => {
    const [query, setQuery] = useState("");

    const filteredOptions =
        query === ""
            ? props.options
            : props.options.filter((option) =>
                  option.searchString.includes(query.toLowerCase()),
              );

    return (
        <Combobox
            value={
                props.options.find((option) => option.id === props.value) ||
                null
            }
            onChange={(p) => props.onChange(p?.id || null)}
        >
            <div className="relative mt-1">
                <Combobox.Input
                    onChange={(event) => setQuery(event.target.value)}
                    placeholder="Type to search"
                    displayValue={(option: any) =>
                        option ? option.displayName : props.emptyValue
                    }
                    className="text-sm rounded w-full relative pr-16"
                />

                <div className="absolute inset-y-0 right-0 flex items-center">
                    {props.extraActions &&
                        props.extraActions.map((action, idx) => (
                            <button
                                key={idx}
                                className="flex items-center p-1"
                                title={action.title}
                                onClick={(e) => {
                                    action.action();
                                    e.preventDefault();
                                }}
                            >
                                {action.icon}
                            </button>
                        ))}

                    <Combobox.Button className="flex items-center p-1 pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                <Combobox.Options className="absolute mt-1 z-10 rounded overflow-hidden border border-gray-300 w-full">
                    {props.emptyValue && (
                        <Combobox.Option value={null} as={Fragment}>
                            {({ active, selected }) => (
                                <li
                                    className={`
                                    p-2 text-sm cursor-pointer
                                    flex items-center gap-2
                                    ${
                                        active
                                            ? "bg-ae-blue-500 text-white"
                                            : "bg-white text-black"
                                    }
                                `}
                                >
                                    {selected && <CheckIcon className="w-4" />}
                                    {props.emptyValue}
                                </li>
                            )}
                        </Combobox.Option>
                    )}
                    {filteredOptions.map((option) => (
                        <Combobox.Option
                            key={option.id}
                            value={option}
                            as={Fragment}
                        >
                            {({ active, selected }) => (
                                <li
                                    className={`
                                    p-2 text-sm cursor-pointer
                                    flex items-center gap-2
                                    ${
                                        active
                                            ? "bg-ae-blue-500 text-white"
                                            : "bg-white text-black"
                                    }
                                `}
                                >
                                    {selected && <CheckIcon className="w-4" />}
                                    {option.displayName}
                                </li>
                            )}
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            </div>
        </Combobox>
    );
};
