import { useState } from "react";
import { ControlButton } from "../../ui/MapControls";
import { useMapData } from "../../hooks/mapDataState";
import { createPortal } from "react-dom";

import {
    faCircleHalfStroke,
    faEye,
    faEyeSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PlumeOpacityControl = ({
    containerRef,
}: {
    containerRef: React.RefObject<HTMLDivElement>;
}) => {
    const {
        mapSettings: { plumeOpacity },
        setMapDataState,
    } = useMapData("mainMap");
    const [showOpacityControl, setShowOpacityControl] = useState(false);
    const [oldOpacity, setOldOpacity] = useState(0);

    return (
        <>
            <ControlButton
                className="gap-2 px-3 text-sm rounded text-nowrap"
                selected={showOpacityControl}
                onClick={() => setShowOpacityControl(!showOpacityControl)}
            >
                <FontAwesomeIcon icon={faCircleHalfStroke} className="w-4" />
                Plume opacity
            </ControlButton>
            {showOpacityControl &&
                containerRef.current &&
                createPortal(
                    <div className="w-full mt-2 flex gap-3 bg-white rounded px-4 py-3 text-sm whitespace-nowrap items-center">
                        Plume opacity
                        <button
                            className="px-1 rounded hover:bg-gray-200"
                            onClick={() => {
                                setMapDataState((ds) => ({
                                    ...ds,
                                    mapSettings: {
                                        ...ds.mapSettings,
                                        plumeOpacity: oldOpacity,
                                    },
                                }));
                                setOldOpacity(plumeOpacity);
                            }}
                        >
                            <FontAwesomeIcon
                                icon={plumeOpacity ? faEye : faEyeSlash}
                                className="w-4"
                            />
                        </button>
                        <input
                            type="range"
                            className="w-full"
                            value={plumeOpacity}
                            min={0}
                            step={0.01}
                            max={1}
                            onChange={(e) => {
                                setMapDataState((ds) => ({
                                    ...ds,
                                    mapSettings: {
                                        ...ds.mapSettings,
                                        plumeOpacity: e.target.valueAsNumber,
                                    },
                                }));
                            }}
                        />
                        {(plumeOpacity * 100).toFixed(0)} %
                    </div>,
                    containerRef.current,
                    "plumeOpacityControl",
                )}
        </>
    );
};
