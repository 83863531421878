import {
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { MapDropdownMenu } from "../../ui/MapControls";
import { useContext, useEffect, useState } from "react";
import { MapContext } from "../../MapView";
import { CustomSwitch } from "../../../../ui/CustomSwitch";
import { PillButton } from "../../../../ui/Buttons";
import { DateTime } from "luxon";
import { Checkbox, CheckboxWithMenu } from "../../ui/Menu";
import { EMISSION_COLORS } from "../../constants";
import { useDebounce } from "@uidotdev/usehooks";
import { useAppSelector } from "../../../../hooks";

const LegendColorCircle = (props: { color: [number, number, number] }) => (
    <div
        className="rounded-full h-4 w-4"
        style={{ backgroundColor: `rgb(${props.color.join(",")}` }}
    />
);

export const ValidatingDateField = ({
    value,
    onChange,
}: {
    value?: Date;
    onChange: (v: Date) => void;
}) => {
    const [inputValue, setInputValue] = useState(
        value ? value.toISOString().split("T")[0] : "",
    );
    const [error, setError] = useState(false);
    const debouncedInputValue = useDebounce(inputValue, 300);

    // If value changes, update internal state
    useEffect(() => {
        setInputValue(value ? value.toISOString().split("T")[0] : "");
    }, [value]);

    // Check if the debounced input value is a valid date
    useEffect(() => {
        const isValidDate = (dateString) => {
            const date = new Date(dateString);
            return !isNaN(date.getTime()) && date.getFullYear() > 1950;
        };

        if (inputValue === "") {
            setError(false);
            return;
        }

        if (isValidDate(debouncedInputValue)) {
            setError(false);
            onChange(new Date(debouncedInputValue));
        } else {
            setError(true);
        }
    }, [debouncedInputValue]); // Adding `onChange` here causes issues (infinite setstate loop)

    return (
        <>
            <input
                type="date"
                className={`rounded p-1 text-sm w-full ${
                    error && "border border-red-500"
                }`}
                value={inputValue}
                onChange={(event) => {
                    setInputValue(event.target.value);
                }}
            />
            {error && <span className="text-red-500">Invalid date</span>}
        </>
    );
};

export const EmissionsControl = () => {
    const flags = useAppSelector((state) => state.auth.flags);
    const emissionFilters = useContext(MapContext).filters.emissions;

    return (
        <div className="flex divide-x">
            <MapDropdownMenu
                button={
                    <div className="flex h-8 w-40 text-sm items-center justify-between py-2 px-4 bg-white rounded-l">
                        Emissions
                        <ChevronDownIcon className="h-4" />
                    </div>
                }
            >
                <div className="w-64 text-sm">
                    <div className="flex p-1">
                        <CustomSwitch
                            size="sm"
                            checked={emissionFilters.showEmissions}
                            onChange={emissionFilters.toggleShowEmissions}
                        />
                        <label htmlFor="enableInfrastructure" className="ml-4">
                            Show on map
                        </label>
                    </div>
                    <div className="flex p-1">
                        <CustomSwitch
                            size="sm"
                            checked={emissionFilters.showPlumes}
                            onChange={emissionFilters.toggleShowPlumes}
                            disabled={!emissionFilters.showEmissions}
                        />
                        <label
                            htmlFor="enableInfrastructure"
                            className={`ml-4 ${
                                !emissionFilters.showEmissions &&
                                "text-gray-400"
                            }`}
                        >
                            Show plumes
                        </label>
                    </div>
                    {flags.includes("show_public_emissions") && (
                        <div className="flex p-1">
                            <CustomSwitch
                                size="sm"
                                checked={emissionFilters.showPublicData}
                                onChange={emissionFilters.toggleShowPublicData}
                                disabled={!emissionFilters.showEmissions}
                            />
                            <label
                                htmlFor="enableInfrastructure"
                                className={`ml-4 ${
                                    !emissionFilters.showEmissions &&
                                    "text-gray-400"
                                }`}
                            >
                                Show all public data
                            </label>
                        </div>
                    )}
                    <hr className="mt-2" />
                    <p className="px-2 mt-3 mb-1 font-medium tracking-wide uppercase text-xs text-zinc-600">
                        Emission types
                    </p>
                    <div className="p-2 flex flex-col gap-2">
                        <CheckboxWithMenu
                            label="3rd Party"
                            checked={emissionFilters.show3rdPartyEmissions}
                            icon={
                                <LegendColorCircle
                                    color={EMISSION_COLORS.thirdParty}
                                />
                            }
                            onChange={
                                emissionFilters.toggleShow3rdPartyEmissions
                            }
                        >
                            {Object.values(emissionFilters.dataProviders).map(
                                (e) => (
                                    <Checkbox
                                        key={e.id}
                                        label={e.name}
                                        checked={emissionFilters.providerFilter.includes(
                                            e.id,
                                        )}
                                        onChange={() => {
                                            emissionFilters.toggleProviderFilter(
                                                e.id,
                                            );
                                        }}
                                    />
                                ),
                            )}
                        </CheckboxWithMenu>

                        {/* Placeholders for future emission types */}
                        <CheckboxWithMenu
                            label="My Company Monitoring"
                            disabled={false}
                            checked={emissionFilters.showSelfReportedEmissions}
                            icon={
                                <LegendColorCircle
                                    color={EMISSION_COLORS.operatorProvided}
                                />
                            }
                            onChange={
                                emissionFilters.toggleShowSelfReportedEmissions
                            }
                        >
                            {Object.values(
                                emissionFilters.selfReportedDataProviders,
                            ).map((e) => (
                                <Checkbox
                                    key={e.id}
                                    label={e.name}
                                    checked={emissionFilters.selfReportedProviderFilter.includes(
                                        e.id,
                                    )}
                                    onChange={() => {
                                        emissionFilters.toggleSelfReportedProviderFilter(
                                            e.id,
                                        );
                                    }}
                                />
                            ))}
                        </CheckboxWithMenu>
                        <CheckboxWithMenu
                            label="EPA SEP"
                            disabled={false}
                            checked={emissionFilters.showEpaEmissions}
                            icon={
                                <LegendColorCircle
                                    color={EMISSION_COLORS.epa}
                                />
                            }
                            onChange={emissionFilters.toggleShowEpaEmissions}
                        />
                    </div>
                </div>
            </MapDropdownMenu>
            <MapDropdownMenu
                button={
                    <div className="flex h-8 w-60 text-sm items-center justify-between py-2 px-4 bg-white rounded-r">
                        {DateTime.fromJSDate(
                            emissionFilters.startDateFilter,
                        ).toLocaleString(DateTime.DATE_MED)}
                        {" - "}
                        {DateTime.fromJSDate(
                            emissionFilters.endDateFilter,
                        ).toLocaleString(DateTime.DATE_MED)}
                        <ChevronDownIcon className="h-4" />
                    </div>
                }
            >
                <div className="p-1 text-sm flex gap-2 w-80">
                    <div className="w-1/2">
                        Start date:
                        <ValidatingDateField
                            value={emissionFilters.startDateFilter}
                            onChange={(c) =>
                                emissionFilters.setStartDateFilter(c)
                            }
                        />
                    </div>
                    <div className="w-1/2">
                        End date:
                        <ValidatingDateField
                            value={emissionFilters.endDateFilter}
                            onChange={(c) =>
                                emissionFilters.setEndDateFilter(c)
                            }
                        />
                    </div>
                </div>
                {emissionFilters.startDateFilter >
                emissionFilters.endDateFilter ? (
                    <p className="text-red-500 text-sm">
                        Start date must be before end date
                    </p>
                ) : (
                    <div className="grid grid-cols-2 gap-2 px-1 mt-1 whitespace-nowrap">
                        <PillButton
                            onClick={() => {
                                const onePeriod =
                                    emissionFilters.endDateFilter.getTime() -
                                    emissionFilters.startDateFilter.getTime();
                                const newStartDate = new Date(
                                    emissionFilters.startDateFilter.getTime() -
                                        onePeriod,
                                );
                                const newEndDate = new Date(
                                    emissionFilters.endDateFilter.getTime() -
                                        onePeriod,
                                );
                                emissionFilters.setStartDateFilter(
                                    newStartDate,
                                );
                                emissionFilters.setEndDateFilter(newEndDate);
                            }}
                        >
                            <ChevronLeftIcon className="h-3" />
                            Back 1 period
                        </PillButton>
                        <PillButton
                            onClick={() => {
                                const onePeriod =
                                    emissionFilters.endDateFilter.getTime() -
                                    emissionFilters.startDateFilter.getTime();
                                const newStartDate = new Date(
                                    emissionFilters.startDateFilter.getTime() +
                                        onePeriod,
                                );
                                const newEndDate = new Date(
                                    emissionFilters.endDateFilter.getTime() +
                                        onePeriod,
                                );
                                emissionFilters.setStartDateFilter(
                                    newStartDate,
                                );
                                emissionFilters.setEndDateFilter(newEndDate);
                            }}
                        >
                            Forward 1 period
                            <ChevronRightIcon className="h-3" />
                        </PillButton>
                    </div>
                )}

                <hr className="my-2" />
                <div className="px-1 grid grid-cols-2 gap-2 text-sm mb-1">
                    <PillButton
                        onClick={() => {
                            const today = new Date();
                            emissionFilters.setEndDateFilter(today);
                            emissionFilters.setStartDateFilter(
                                new Date(
                                    new Date().setMonth(
                                        new Date().getMonth() - 1,
                                    ),
                                ),
                            );
                        }}
                    >
                        Last month
                    </PillButton>
                    <PillButton
                        onClick={() => {
                            const today = new Date();
                            emissionFilters.setEndDateFilter(today);
                            emissionFilters.setStartDateFilter(
                                new Date(
                                    new Date().setMonth(
                                        new Date().getMonth() - 3,
                                    ),
                                ),
                            );
                        }}
                    >
                        Last 3 months
                    </PillButton>
                    <PillButton
                        onClick={() => {
                            const today = new Date();
                            emissionFilters.setEndDateFilter(today);
                            emissionFilters.setStartDateFilter(
                                new Date(
                                    new Date().setMonth(
                                        new Date().getMonth() - 6,
                                    ),
                                ),
                            );
                        }}
                    >
                        Last 6 months
                    </PillButton>
                    <PillButton
                        onClick={() => {
                            const today = new Date();
                            emissionFilters.setEndDateFilter(today);
                            emissionFilters.setStartDateFilter(
                                new Date(
                                    new Date().setMonth(
                                        new Date().getMonth() - 12,
                                    ),
                                ),
                            );
                        }}
                    >
                        Last year
                    </PillButton>
                </div>
            </MapDropdownMenu>
        </div>
    );
};
